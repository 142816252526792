import { AppContainer, setConfig } from 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import withErrorBoundary from 'HOC/withErrorBoundary';
import App from './components/App';
import * as Sentry from '@sentry/browser';

import './index.scss';

Sentry.init({
  dsn: SENTRY_DSN,
  environment: API_ENV,
  release: COMMIT_HASH,
});

window.fiftyfifty = {
  SENTRY_DSN,
  API_ENV,
  API_BASE_URL,
  COMMIT_HASH,
};

setConfig({
  ignoreSFC: true,
});

const render = Component =>
  ReactDOM.render(
    <AppContainer>
      <Component />
    </AppContainer>,
    document.getElementById('root')
  );

render(withErrorBoundary(App));
if (module.hot) module.hot.accept('./components/App', () => render(App));
