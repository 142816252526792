import React from 'react';
import withCurrentUser from 'HOC/withCurrentUser';
import { Redirect } from 'react-router';
import { Grid } from 'semantic-ui-react';
import Layout from './layout/normal';

const Home = withCurrentUser(({ user }) => {
  if (!user) return <Redirect to="/comingsoon" />;
  return (
    <Layout>
      <Grid stackable columns={2}>
        <Grid.Row>
          <Grid.Column>Welcome</Grid.Column>
        </Grid.Row>
      </Grid>
    </Layout>
  );
});

export default Home;
